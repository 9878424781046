<template>
  <DefaultTemplate
    v-loading.lock="loadingExport || loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page">
        <p class="title">Transaction Log</p>
        <div class="is-flex ai-center">
          <el-button
            type="primary"
            @click="exportCSV"
            v-if="checkPermissionMore()"
          >
            Export
          </el-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ค้นหา">
              <el-input
                id="searchTransactionLogName"
                placeholder="ค้นหาชื่อ"
                v-model="searchData"
                clearable
                class="search"
                @input="searchTransactionLog(600)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="Action Type">
              <el-select
                id="selectActionType"
                v-model="actionType"
                placeholder="แสดงทั้งหมด"
                @change="searchTransactionLog"
                filterable
                clearable
              >
                <el-option
                  v-for="item in optionsActionType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สถานะ">
              <el-select
                id="selectActionStatus"
                v-model="actionStatus"
                placeholder="แสดงทั้งหมด"
                @change="searchTransactionLog"
                filterable
                clearable
              >
                <el-option
                  v-for="item in optionsStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ช่วงวันที่">
              <el-date-picker
                id="selectDateRange"
                v-model="dateRange"
                clearable
                type="daterange"
                class="w-100"
                range-separator="-"
                start-placeholder="เริ่มต้น"
                end-placeholder="สิ้นสุด"
                @change="searchTransactionLog"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div
      class="box-card mg-t-5 animate__animated animate__fadeIn animate__slow"
    >
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24">
            <el-table
              class="table-normal animate__animated animate__fadeIn animate__slow"
              :data="tableData"
              style="width: 100%"
              v-loading="loading"
              element-loading-text="กำลังโหลดข้อมูล..."
              element-loading-spinner="el-icon-loading"
            >
              <template slot="empty">
                <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
              </template>

              <el-table-column label="วันที่" prop="createdAt">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.logTime | formatDateReport }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="ชื่อผู้ใช้งาน">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.employee != '' && scope.row.employee != null
                    "
                  >
                    {{
                      scope.row.employee.empFname +
                      " " +
                      scope.row.employee.empLname
                    }}
                  </span>
                  <span
                    v-else-if="
                      scope.row.activity.actionDescTh == 'เข้าสู่ระบบ' &&
                      scope.row.logStatus == 2
                    "
                    >{{ scope.row.desc }}</span
                  >
                  <span v-else>
                    <span
                      v-if="
                        scope.row.activity.actionDescTh ==
                          'Sign in LOS / CRM' ||
                        scope.row.activity.actionDescTh ==
                          'อัปเดตสถานะ Lead จาก CRM'
                      "
                    >
                      CRM/LOS
                    </span>
                    <span
                      v-else-if="
                        scope.row.activity.actionDescTh ==
                          'ส่งข้อมูลไปยัง CRM' ||
                        scope.row.activity.actionDescTh ==
                          'Request ข้อมูลและรายละเอียด Lead' ||
                        scope.row.activity.actionDescTh ==
                          'ส่งข้อมูลไปยัง CRM / สร้างข้อมูล CRM Lead' ||
                        scope.row.activity.actionDescTh ==
                          'Sync ข้อมูลพนักงาน (WS)'
                      "
                    >
                      <!-- สร้างข้อมูล CRM Lead -->
                      System
                    </span>
                    <span
                      v-else-if="
                        scope.row.activity.actionDescTh ==
                        'Interest Product From Channel Integration'
                      "
                    >
                      Channel Integration
                    </span>
                    <span
                      v-else-if="
                        scope.row.activity.actionDescTh ==
                        'Interest Product From TERAK'
                      "
                    >
                      TERAK
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="IP Address" prop="ipAddress">
              </el-table-column>
              <el-table-column label="ความเคลื่อนไหว">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.activity.actionDescTh }}
                    <span
                      v-if="
                        scope.row.activity.actionDescTh ==
                          'Sign in LOS / CRM' ||
                        scope.row.activity.actionDescTh ==
                          'ส่งข้อมูลไปยัง CRM' ||
                        scope.row.activity.actionDescTh ==
                          'Request ข้อมูลและรายละเอียด Lead' ||
                        scope.row.activity.actionDescTh ==
                          'อัปเดตสถานะ Lead จาก CRM' ||
                        scope.row.activity.actionDescTh ==
                          'ส่งข้อมูลไปยัง CRM / สร้างข้อมูล CRM Lead' ||
                        scope.row.activity.actionDescTh ==
                          'Sync ข้อมูลพนักงาน (WS)' ||
                        scope.row.activity.actionDescTh ==
                          'Interest Product From Channel Integration' ||
                        scope.row.activity.actionDescTh ==
                          'Interest Product From TERAK' ||
                        scope.row.activity.actionDescTh ==
                          'Failed Request ข้อมูลและรายละเอียด Enlite'
                      "
                    >
                      <!-- สร้างข้อมูล CRM Lead -->
                      <br />
                      <span
                        class="color-main cursor-pointer"
                        @click="seeMoreAction(scope.row)"
                        >ดูเพิ่มเติม</span
                      >
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="สถานะ">
                <template slot-scope="scope">
                  <span v-if="scope.row.logStatus == 0" style="color: #fac858">
                    Pending
                  </span>
                  <span
                    v-else-if="scope.row.logStatus == 1"
                    style="color: rgb(56, 216, 91)"
                  >
                    Success
                  </span>
                  <span
                    v-else-if="scope.row.logStatus == 2"
                    style="color: rgb(255, 100, 106)"
                  >
                    Fail
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <div class="text-center mg-t-5">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="changePage"
                :current-page.sync="pageNow"
                :page-sizes="[15, 30, 50, 100, 500]"
                :page-size="15"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalNow"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-form>
      </el-row>
    </div>

    <el-dialog
      id="dialogAction"
      :visible.sync="dialogAction"
      width="70%"
      v-if="dialogAction"
    >
      <h3 class="mg-0">
        Request:
        {{ actionDetail.url }}
        <!-- {{
          actionDetail.activity.method +
          " " +
          urlBase1 +
          "" +
          actionDetail.activity.path
        }} -->
      </h3>
      <div class="ql-syntax">
        <pre>
        {{ actionDetail.payload }}
        </pre>
      </div>
      <h3 class="mg-0 pd-y-5">Response</h3>
      <div class="ql-syntax">
        <pre>
        {{ actionDetail.response }}
       </pre
        >
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
  },
  data() {
    return {
      searchData: "",
      totalNow: 10,
      limit: 10,
      pageNow: 1,
      actionType: "",
      actionStatus: "",
      tableData: [],
      dateRange: [],
      optionsActionType: [
        {
          value: "",
          label: "แสดงทั้งหมด",
        },
      ],
      optionsStatus: [
        {
          value: "",
          label: "แสดงทั้งหมด",
        },
        {
          value: "1",
          label: "Success",
        },
        {
          value: "0",
          label: "Pending",
        },
        {
          value: "2",
          label: "Fail",
        },
      ],
      logTimeStart: "",
      logTimeEnd: "",
      loading: true,
      debounce: null,
      loadingExport: false,
      urlBase: process.env.VUE_APP_BASE_URL,
      urlBase1: "",
      loadingData: true,
      actionDetail: null,
      dialogAction: false,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "transaction-log");
    this.getActionType();
    this.getTransactionLog();
    this.urlBase1 = this.urlBase.substring(0, this.urlBase.length - 1);
  },
  methods: {
    seeMoreAction(detail) {
      this.dialogAction = true;
      this.actionDetail = detail;
      this.actionDetail.payload = JSON.parse(detail.payload);
      this.actionDetail.response = JSON.parse(detail.response);
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    getActionType() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`action`)
        .then((res) => {
          if (res.data.success) {
            res.data.obj.forEach((e) => {
              this.optionsActionType.push({
                value: e._id,
                label: e.name,
              });
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    searchTransactionLog(time = 0) {
      this.pageNow = 1;
      this.loading = true;
      if (this.dateRange != null) {
        if (this.dateRange.length > 0) {
          this.logTimeStart = moment(this.dateRange[0]).format(
            "YYYY-MM-DD 00:00:00"
          );
          this.logTimeEnd = moment(this.dateRange[1]).format(
            "YYYY-MM-DD 23:59:59"
          );
        }
      } else {
        this.logTimeStart = "";
        this.logTimeEnd = "";
      }

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getTransactionLog();
      }, time);
    },
    async getTransactionLog() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageNow,
        limit: this.limit,
        key: this.searchData,
        logStatus: this.actionStatus,
        actionId: this.actionType,
        logTimeStart: this.logTimeStart,
        logTimeEnd: this.logTimeEnd,
      };
      let res = await HTTP.post(`log`, obj)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.obj.data;
            this.totalNow = res.data.obj.totalPage;
            this.loading = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }

          this.loadingData = false;

          return res;
        })
        .catch((e) => {
          this.loadingData = false;
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        });
      return res;
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.pageNow = page;
      this.loading = true;
      setTimeout(() => {
        this.getTransactionLog();
      }, 600);
      // window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    exportCSV() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`log/export`)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
  },
};
</script>
